import React from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
//import SimpleDialog from '@mui/material/SimpleDialog';


import { useFirestoreConnect } from 'react-redux-firebase'
import { isLoaded  } from  'react-redux-firebase'


import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles({
    ...mainStyle,
    ImageGallery:{
      width: "100%",
      
        //background: "#efe;",
        marginRight:"20px",
    },
    mainImage:{
      width: "100%",
      height:" 400px;",
      marginRight:"20px",
      objectFit: "contain;",
      textDecoration: "none;"
    },
    images:{
      //display: "in;"
    },
    ImageSelect:{
      width: "80px",
      height:" 80px;",
      float: "left;",
      marginRight:" 10px;"
    },
    thumbnailImage:{
      width: "80px",
      height:" 80px;",
      objectFit: "cover;",
      objectPosition: "center;",
      textDecoration: "none;",
      cursor: "pointer;"
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      //padding: "30px;",
      padding: "5vh 5vw;",
      outline:" none;",
    },
    modalImage: {
      maxHeight: "90vh;",
      maxWidth: "90vw;",
      padding: "20px;",
      background: "#ffffff1f;"
    }
});

const ImageGallery = ({GalleryAttachments,Attachments,projectDetails}) => {
    const classes = useStyles();
    
    const [open, setOpen] = React.useState();
    const [displayImage, setDisplayImage] = React.useState(null);

    var whereQuery = [];
    
    if(Attachments && Array.isArray(Attachments) && Attachments.length > 0)
    {
      whereQuery.push({ collection: 'Attachments', storeAs: 'GalleryAttachments', where:[["status","==","public"],[["id","in",Attachments.length > 10 ? Attachments.slice(0, 10) : Attachments]]]})
    }
    if(displayImage  && displayImage.id  && !Attachments.includes(displayImage.id))
    {
      setDisplayImage(null)
    }

    var MainImage = displayImage && displayImage.url ? displayImage.url : GalleryAttachments && GalleryAttachments[0] && GalleryAttachments[0].url ?  GalleryAttachments[0].url : null

    
    useFirestoreConnect(whereQuery)
        
      const handleOpen = () => {
        setOpen(true);
      };

    const handleClose = () => {
        setOpen(false);
    };

    return  <Box className={classes.ImageGallery}>

                <Box >
                    <img alt={projectDetails.title+"Main"} onClick={handleOpen} className={classes.mainImage} src={MainImage}/>
                </Box>

                <Box className={classes.images}>
                    {GalleryAttachments && GalleryAttachments.length > 1 && GalleryAttachments.map((value,index) => {
                     
                        return (<Box className={ classes.ImageSelect}>
                                    <img src={value.thumbnailUrl?value.thumbnailUrl:value.url}  alt={projectDetails.title+index} onClick={() => setDisplayImage(value)} className={ classes.thumbnailImage}/>
                                </Box>)
                    })}
                </Box>


                <Modal
                    disablePortal
                    disableEnforceFocus
                    disableAutoFocus
                    open={open}
                    onClose={handleClose}
                >
                    <Box  onClick={handleClose} className={classes.modal}>
                       { <img  alt={projectDetails.title+"Large"} className={classes.modalImage} src={MainImage}/>}
                    </Box>
                </Modal>

            </Box>
}




const mapStateToProps = (state, ownProps) => {
  return {
    GalleryAttachments: state.firestore.ordered.GalleryAttachments,
    Loaded:isLoaded(state.firestore.ordered.GalleryAttachments),
    //Empty:isEmpty(state.firestore.ordered.GalleryAttachments) 
  }
}

export default connect(mapStateToProps)(ImageGallery);
