import React from 'react'
import { NavLink ,Link} from 'react-router-dom'

import { useLocation   } from 'react-router-dom'

import { makeStyles } from "@mui/styles";
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu'; 
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';

import { FaDiscord,FaGithub,FaYoutube,FaTwitter } from 'react-icons/fa';



import mainStyle from "../../assets/mainStyle";
const Styles = {
    ...mainStyle,
    headerBox:{
        width: "100%;",
        backgroundColor:"transparent",
        boxShadow:"none !important",
        backgroundImage: "url(/images/tinkers-projects-background-header.png)",
        backgroundRepeat: "no-repeat;",
        backgroundAttachment: "fixed;",
        backgroundPosition: "center -70vh;",
        backgroundSize: "cover;",
        WebkitBackgroundSize: "cover;",
        MozBackgroundSize:" cover;",
        OBackgroundSize: "cover;",
    },
    titleBox:{
        transition: "margin-bottom 0.2s;",
        zIndex:" 3;",
        bottom: "0;",
        display: "block;",
        left: "0;",
        paddingTop: "0;",
        width: "100%;",
        marginBottom:" 70px;",
        paddingBottom:"48px",
        marginTop:" 70px;",
    },
    siteTitle:{
        color: "#595959;",
        opacity: 1,
        textDecoration: "none;",
        WebkitTransition: "opacity 0.2s;",
        transition: "opacity 0.2s;",
        fontWeight: "700;",
        fontSize: "36px",
        lineHeight: "1.25;",
        letterSpacing: "0.08em;",
        margin: 0,
        padding: 0
    },
    siteDescription:{
        color: "#595959;",
        opacity: 0.8,
        fontSize: "16px",
        marginBottom: 0,
        margin: "0 0 1.5em;",
        padding: 0,
        lineHeight:" 1.5;",
    },
    MenuBox:{
        bottom: "0;",
        fontSize: "14px;",
        left: "0;",
        position:" absolute;",
        right: "0;",
        width: "100%;",
        zIndex: "3;",
        background:" #fff;",
        borderBottom:" 1px solid #eee;",
        borderTop: "1px solid #eee;",
    },
    MenuHeaderButton:{
        color: "#767676 !important",
        textDecoration: "none;",
        fontSize: "18px;",
        marginRight: "20px;",
        padding:"20px 0"
    },
    MenuHeaderButtonActive:{
        color: "#222 !important",
        textDecoration: "none;",
        fontSize: "18px;",
        marginRight: "20px;",
        padding:"20px 0",
        fontWeight:600,
    },
    MenuHeaderButtonSocula:{
        color: "#767676 !important",
        textDecoration: "none;",
        //fontSize: "18px;",
        marginRight: "5px;",
        padding:"10px 0",
        fontSize: "24px !important"
    },
    mobileMenu:{
        borderBottom:" 1px solid #eee;"
    },
    tindieLog:{
        fontSize: "24px !important",
        display: "inline-block;",
        backgroundImage: "url(/images/tindie-Logo-Black.svg);",
        backgroundColor: "transparent;",
        backgroundRepeat:" no-repeat;",
        backgroundSize: "contain;",
        width: "30px;",
        height: "24px;",
        padding:"20px 0",
        backgroundPosition: "left center;",
        position: "relative;",
    },
    Dropdown:{
        padding:"20px 0",
        "&:hover": {
            "& $DropdownMenu": {
                display: 'block'
            }
        }
    },
    DropdownMenu:{
        position: "absolute;",
        top: "70px;",
        boxShadow: "0px 5px 9px grey;",
        background: "#fff;",
        display: 'none',
        "&:hover": {
            display: 'block'
        }
    }
};

const HomePageStyle = {
    ...Styles,
    headerBox:{
        height:"100vh",
        width: "100%;",
        backgroundColor:"transparent",
        boxShadow:"none !important",
        position: "relative;",
        display: "block;",
        backgroundImage: "url(/images/tinkers-projects-background-header.png)",
        backgroundRepeat: "no-repeat;",
        backgroundAttachment: "fixed;",
        backgroundPosition: "center bottom",
        backgroundSize: "cover;"
    },
    titleBox:{
        transition: "margin-bottom 0.2s;",
        zIndex:" 3;",
        bottom: "0;",
        display: "block;",
        left: "0;",
        height: "auto;",
        paddingTop: "0;",
        position: "absolute;",
        width: "100%;",
        marginBottom:" 70px;",
        paddingBottom:"48px"
    },
    siteTitle:{
        color: "#595959;",
        opacity: 1,
        textDecoration: "none;",
        WebkitTransition: "opacity 0.2s;",
        transition: "opacity 0.2s;",
        fontWeight: "700;",
        fontSize: "36px",
        lineHeight: "1.25;",
        letterSpacing: "0.08em;",
        margin: 0,
        padding: 0
    },
    siteDescription:{
        color: "#595959;",
        opacity: 0.8,
        fontSize: "16px",
        marginBottom: 0,
        margin: "0 0 1.5em;",
        padding: 0,
        lineHeight:" 1.5;",
    },

};



const useStyles = makeStyles(Styles);
const useStylesHomePage = makeStyles(HomePageStyle);



const ActiveLink = (props) => {

    const {Component,className,activeClassName,to,children,exact} = props

    if(to && ((!exact && window.location.pathname.startsWith(to)) || window.location.pathname === to))
        return <Component className={activeClassName} to={to}>{children}</Component>

    return <Component className={className} to={to} >{children}</Component>
}


const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;



const Header = () => {
    
    const classesAll  =  useStyles();
    const classesHome =  useStylesHomePage();


    const [width, setWidth] = React.useState(getWidth());
    const [ActiveMenu, setActiveMenu] = React.useState(false);

    const classes = window.location.pathname === "" || window.location.pathname === "/" ? classesHome : classesAll;

const DeactivateMenu=()=>{
    setActiveMenu(false)
    handleClick()
}
    

    useLocation(state => {
        width(getWidth())
    })
    
    const handleClick = () =>{
        window.scrollTo(0, 0);
    }


    window.addEventListener('resize', function() {
        setWidth(getWidth())
        DeactivateMenu(false)
    });


    return (<>
        <AppBar position="relative" color="transparent" className={classes.headerBox}>
           
            <Box className={classes.titleBox}>
                <Container >
                    
                    <NavLink  onClick={handleClick} className={classes.siteTitle} to="/" rel="home">
                        <h1 className={classes.siteTitle}>Tinkers Projects</h1>
                    </NavLink>
                    
                    <p className={classes.siteDescription} >Imagine | Develop | Create</p>
                </Container>
            </Box>

            <Box  className={classes.MenuBox} >
            <Container>
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: width > 900 ? "flex":"none" }}>
                        <ActiveLink exact  onClick={handleClick} Component={NavLink} className={classes.MenuHeaderButton} activeClassName={classes.MenuHeaderButtonActive}  to="/">Home</ActiveLink>
                        <Box  onClick={handleClick} className={classes.Dropdown}>
                            <ActiveLink  onClick={handleClick} Component={NavLink} className={classes.MenuHeaderButton} activeClassName={classes.MenuHeaderButtonActive}  to="/projects">Projects </ActiveLink>
                            <Box className={classes.DropdownMenu}>
                                <MenuItem onClick={handleClick} component={NavLink} to="/projects"><Typography>All</Typography></MenuItem>
                                <MenuItem onClick={handleClick} component={NavLink} to="/category/experiments/"><Typography>Experiments</Typography></MenuItem>
                                <MenuItem onClick={handleClick} component={NavLink} to="/category/hacks/"><Typography>Hacks</Typography></MenuItem>
                                <MenuItem onClick={handleClick} component={NavLink} to="/category/libraries/"><Typography>Libraries</Typography></MenuItem>
                                <MenuItem onClick={handleClick} component={NavLink} to="/category/lighting/"><Typography>Lighting</Typography></MenuItem>
                                <MenuItem onClick={handleClick} component={NavLink} to="/category/machines/"><Typography>Machines</Typography></MenuItem>
                                <MenuItem onClick={handleClick} component={NavLink} to="/category/plex/"><Typography>PLEX</Typography></MenuItem>
                                <MenuItem onClick={handleClick} component={NavLink} to="/category/repairs/"><Typography>Repairs</Typography></MenuItem>
                                <MenuItem onClick={handleClick} component={NavLink} to="/category/software/"><Typography>Software</Typography></MenuItem>
                                <MenuItem onClick={handleClick} component={NavLink} to="/category/wifi/"><Typography>WiFi</Typography></MenuItem>
                            </Box>
                        </Box>
                        <ActiveLink onClick={handleClick} Component={NavLink} className={classes.MenuHeaderButton} activeClassName={classes.MenuHeaderButtonActive}  to="/reviews">Reviews</ActiveLink>
                        
                        <ActiveLink onClick={handleClick} Component={NavLink} className={classes.MenuHeaderButton} activeClassName={classes.MenuHeaderButtonActive}  to="/manufacturing">Manufacturing</ActiveLink>
                        
                        <Box className={classes.Dropdown}>
                            <ActiveLink onClick={handleClick} Component={NavLink} className={classes.MenuHeaderButton} activeClassName={classes.MenuHeaderButtonActive}  to="/about">About me</ActiveLink>
                            <Box className={classes.DropdownMenu}>
                                <MenuItem onClick={handleClick} component={NavLink} to="/about"><Typography>About me</Typography></MenuItem>
                                <MenuItem onClick={handleClick} component={NavLink} to="/links"><Typography>Links</Typography></MenuItem>
                                <MenuItem onClick={handleClick} component={NavLink} to="/contact-me"><Typography>Contact me</Typography></MenuItem>
                            </Box>
                        </Box>
                        
                        
                        <Button className={classes.MenuHeaderButtonSocula} target="_blank" href="https://twitter.com/TinkersProjects"><FaTwitter/></Button>
                        <Button className={classes.MenuHeaderButtonSocula} target="_blank"  href="https://www.youtube.com/channel/UCaKP_tvYR-g-a7-40U1djMg"><FaYoutube /></Button>
                        <Button className={classes.MenuHeaderButtonSocula} target="_blank"  href="https://github.com/tinkersprojects"><FaGithub /></Button>
                        <Button className={classes.MenuHeaderButtonSocula} target="_blank"  href="https://discord.com/invite/j67JGUE"><FaDiscord /></Button>
                        <Button className={classes.MenuHeaderButtonSocula} target="_blank"  href="https://www.tindie.com/stores/williambailes/?ref=offsite_badges&utm_source=sellers_williambailes&utm_medium=badges&utm_campaign=badge_large"><Box className={classes.tindieLog}/></Button>
                    </Box>

                    <Box sx={{ textAlign:"center", flexGrow: 1, display: width <= 900 ? "flex":"none" }}>
                        <IconButton onClick={()=> setActiveMenu(!ActiveMenu)}  className={classes.MenuButtonButton} >
                            <MenuIcon/>
                        </IconButton >
                    </Box>

                </Toolbar>
            </Container>
            </Box>
                
        </AppBar>

        <Box className={classes.mobileMenu}>
        <Container>
            <Collapse in={ width <= 900 &&  ActiveMenu}>
                <List>
                    <ListItem  button component={Link} exact  to="/" onClick={()=> DeactivateMenu(false)} /*className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}*/>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem  button component={Link} exact  to="/projects" onClick={()=> DeactivateMenu(false)}  /*className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}*/>
                        <ListItemText primary="Projects" />
                    </ListItem>
                    <ListItem  button component={Link} exact  to="/reviews" onClick={()=> DeactivateMenu(false)}  /*className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}*/>
                        <ListItemText primary="Reviews" />
                    </ListItem>
                    <ListItem  button component={Link} exact  to="/manufacturing" onClick={()=> DeactivateMenu(false)}  /*className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}*/>
                        <ListItemText primary="Manufacturing" />
                    </ListItem>
                    <ListItem button component={Link} exact  to="/about" onClick={()=> DeactivateMenu(false)}  /*className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}*/>
                        <ListItemText primary="About me" />
                    </ListItem>
                    <ListItem button component={Link} exact  to="/links" onClick={()=> DeactivateMenu(false)}  /*className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}*/>
                        <ListItemText primary="Links" />
                    </ListItem>
                    <ListItem button component={Link} exact  to="/contact-me" onClick={()=> DeactivateMenu(false)}  /*className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}*/>
                        <ListItemText primary="Contact me" />
                    </ListItem>
                                 
                    <ListItem  onClick={()=> DeactivateMenu(false)}  >
                        <Button className={classes.MenuHeaderButtonSocula} target="_blank"  href="https://twitter.com/TinkersProjects"><FaGithub /></Button>
                        <Button className={classes.MenuHeaderButtonSocula}  target="_blank"  href="https://www.youtube.com/channel/UCaKP_tvYR-g-a7-40U1djMg"><FaYoutube /></Button>
                        <Button  className={classes.MenuHeaderButtonSocula} target="_blank"  href="https://github.com/tinkersprojects"><FaTwitter /></Button>
                        <Button className={classes.MenuHeaderButtonSocula} target="_blank"  href="https://discord.com/invite/j67JGUE"><FaDiscord /></Button>
                        <Button className={classes.MenuHeaderButtonSocula}  target="_blank"  href="https://www.tindie.com/stores/williambailes/?ref=offsite_badges&utm_source=sellers_williambailes&utm_medium=badges&utm_campaign=badge_large"><Box className={classes.tindieLog}/></Button>
                    </ListItem>   

                </List>
                
            </Collapse> 
            </Container>
            </Box>
        </>
    );
}

export default (Header)