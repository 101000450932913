import React, {Component } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from "@mui/styles";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles({
...mainStyle,
});

const View = (props) => {
        
    return null
}


export default (View);