import React, {Component } from 'react'

import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';


import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles({
...mainStyle,
});

const Loader = (props) => {
    const classes = useStyles();
    return <Box className={classes.loading}>
        <CircularProgress  className={classes.CircularProgress}/>
        <h4>Loading</h4>

    </Box>
}

export default (Loader);