import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

//import { getAnalytics, logEvent } from "firebase/analytics";
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect,isLoaded  } from  'react-redux-firebase'

import parse from 'html-react-parser';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Loader from "../components/other/Loader"

import { useFirestoreConnect } from 'react-redux-firebase'
import HorizontalAds from "../components/ads/HorizontalAds"

import mainStyle from "../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    margin: '0 ',
    marginTop: '0',
  },
}));


function View({Page,Links,Loaded}) { 
  const classes = useStyles();

  //const analytics = getAnalytics();
  //logEvent(analytics,"page_view",{"page_location":window.location.pathname})
    document.title = 'Tinkers Projects - Links'
  
  
  useFirestoreConnect([
    { collection: 'Pages', storeAs: 'Page', where:[["path",'==',"/links"],["status",'==',"public"]]}
])


  var catagories = []

  if(Links)
  {
    Links.forEach((Link) => {
      if(Link && Link.category && !(Link.category in catagories))
      catagories.push(Link.category)
    })
  }
  
  return (
              <Container>
                
                {!Loaded ? <Loader/> :
                  <main className={classes.root}>

                    {Page && Page.content  && Page.path === window.location.pathname ? parse(Page.content) : null}

                    <HorizontalAds/>

                    {catagories && catagories.map((categoryValue,categoryIndex) => {

                        return (<Box>
                            <h3>{categoryValue}</h3>
                            <ul>
                                {Links && Links.map((LinkValue,LinkIndex) => {

                                return (<li><a href={LinkValue.url} target="_blank" rel="noreferrer">{LinkValue.name} ({LinkValue.url})</a>: {LinkValue.description}</li>)
                                })}
                            </ul>
                        </Box>)
                    })}
                    
                    <HorizontalAds/>
                  </main>
                }
              </Container>);
}







const mapStateToProps = (state, ownProps) => {
  return {
    Page: state.firestore.ordered.Page && state.firestore.ordered.Page[0] ? state.firestore.ordered.Page[0] : null,
    Links:state.firestore.ordered.Links,
    Loaded:isLoaded(state.firestore.ordered.Links) && isLoaded(state.firestore.ordered.Page)
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props =>{
    return [
      { collection: 'Links', storeAs: 'Links',where:[["status",'==',"public"]]},
      //{ collection: 'Pages', storeAs: 'Page', where:[["path",'==','/links']]}
    ]
})
)(View);



