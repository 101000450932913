import React from 'react';
import { makeStyles } from '@mui/styles';

import { Link  } from 'react-router-dom'
import { connect } from 'react-redux'
import {  useParams} from "react-router-dom";

//import { getAnalytics, logEvent } from "firebase/analytics";
import parse from 'html-react-parser';
import { useFirestoreConnect } from 'react-redux-firebase'
import { isLoaded,isEmpty  } from  'react-redux-firebase'


import {Divider ,} from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Loader from "../../components/other/Loader"
import Page404 from "../Page404"


import LatestProjects from "../../components/Projects/LatestProjects"
import PopularProjects from "../../components/Projects/PopularProjects"

import HorizontalAds from "../../components/ads/HorizontalAds"
import Spacer from "../../components/other/Spacer"

import ImageGallery from "../../components/Projects/ImageGallery"


import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  ImageGalleryBox:{
      width:"300px",
      minWidth: "300px;",
      display:"block",
      ['@media (max-width:899px)']: { // eslint-disable-line no-useless-computed-key
          width: '100%',
          minWidth: "100%;",
      }
  },
  TitleBlock:{
    marginBottom:"30px"
  },
  Name:{
    margin:0,
    //topMargin:"10px",
    marginBottom:"0px"
  },
  GridLayout:{
    display: "flex;",
    position: "relative;",
    //alignItems: "center;",
  },
  MainText:{
    flexGrow:1
  },
  CodeBlock:{
    overflow: "scroll;",
    height: "90%;",
    maxHeight: "400px;",
    background: "#eee;",
    fontSize: "15px;",
    lineHeight: "1.6;",
    maxWidth: "100%;",
    padding: "1.6em;",
  }
}));


function View({Empty,Review,Loaded}) { 
  const classes = useStyles();

  //const analytics = getAnalytics();

  let params = useParams();

/*var whereQuery = [["status","==","public"]]
  if(params && params.id)
  {
    whereQuery.push(["slug","==",params.id])
  }*/

  var Query = [
    { collection: 'Reviews', storeAs: 'Review', doc:params.id},
  ]

  document.title = 'Tinkers Projects - ' +(Review && Review.title ? Review.title  : "Review")
  
  useFirestoreConnect(Query)

  
  

if(!Loaded)
  return(<Loader/>)

if(Empty || !params || !params.id)
  return(<Page404/>)

  if(Review && Review.id)
  {
    //logEvent(analytics,"page_view",{"page_location":window.location.pathname})
  }

  const ImageGalleryImages =  Review.galleryImages

  return (
              <Container>
                    <HorizontalAds/>

                <Box className={classes.MainText} display={{xs:"block",sm:"block",md:"none"}}>
                  <Box className={classes.TitleBlock}>
                    <h2 className={classes.Name}>{Review.title ? Review.title:null}</h2>
                    
                    {/*categoriesList && categoriesList.length > 0 ? <Box>
                          <strong>Categories:</strong> 
                            { categoriesList.map((item, index) =>{
                              return (<>{index > 0 ? "," : null} <Link  to={"/category/"+item.slug}>{item.name}</Link ></>)
                            })}
                          </Box> : null*/}
                          
                        {Review && Review.tags && Array.isArray(Review.tags) && Review.tags.length > 0 ? <Box>
                          <strong>Tags:</strong> 
                            { Review.tags.map((item, index) =>{
                              return (<>{index > 0 ? "," : null} <Link  to={"/tag/"+item}>{item}</Link ></>)
                            })}
                          </Box> : null}
                        {/*Review. ? <><Typography variant="h4" component={Link}  to={"/profile/"+IssuesDetails.owner} className={classes.OwnerProfile}>by {Profiles[IssuesDetails.owner].displayName}</Typography><p className={classes.Seperator}>|</p></>:null*/}
                        

                          {Review && (Review.url ||Review.client ||Review.product)   ? <Divider /> : null}
                          {Review && Review.url  ? <Box><a target="_blank" rel="noreferrer" href={Review.url}>{Review.url}</a ></Box> : null}
                          {Review && Review.client  ? <Box><a target="_blank" rel="noreferrer" href={Review.client}>{Review.client}</a ></Box> : null}
                          {Review && Review.product  ? <Box><strong>product:</strong> <a target="_blank" rel="noreferrer" href={Review.product}>{Review.product}</a ></Box> : null}

                        </Box>
                </Box>





                <Box className={classes.GridLayout}>
                    {Review && (Review.galleryImages || Review.image) ?
                      <>
                        <Box className={classes.ImageGalleryBox}>
                            <ImageGallery projectDetails={Review ? Review:null} Attachments={ImageGalleryImages}/>
                        </Box>
                        <Spacer width="20px" display={{xs:"none",sm:"none",md:"block"}}/>
                      </>
                    :null}
                    

                    <Box className={classes.MainText} display={{xs:"none",sm:"none",md:"block"}}>
                  <Box className={classes.TitleBlock}>
                        <h2 className={classes.Name}>{Review.title ? Review.title:null}</h2>
                        
                        {/*categoriesList && categoriesList.length > 0 ? <Box>
                          <strong>Categories:</strong> 
                            { categoriesList.map((item, index) =>{
                              return (<>{index > 0 ? "," : null} <Link  to={"/category/"+item.slug}>{item.name}</Link ></>)
                            })}
                          </Box> : null*/}
                          
                        {Review && Review.tags && Array.isArray(Review.tags) && Review.tags.length > 0 ? <Box>
                          <strong>Tags:</strong> 
                            { Review.tags.map((item, index) =>{
                              return (<>{index > 0 ? "," : null} <Link  to={"/tag/"+item}>{item}</Link ></>)
                            })}
                          </Box> : null}



                          {Review && (Review.url ||Review.client ||Review.product)   ? <Divider /> : null}
                          {Review && Review.url  ? <Box><a target="_blank" rel="noreferrer" href={Review.url}>{Review.url}</a ></Box> : null}
                          {Review && Review.client  ? <Box><a target="_blank" rel="noreferrer" href={Review.client}>{Review.client}</a ></Box> : null}
                          {Review && Review.product  ? <Box><strong>product:</strong> <a target="_blank" rel="noreferrer" href={Review.product}>{Review.product}</a ></Box> : null}



                        
                    </Box>
                        <Box className={classes.contents}>
                            {Review.content ? parse(Review.content):null}
                        </Box>
                    </Box>
                </Box>


                <HorizontalAds  display={{xs:"block",sm:"block",md:"none"}} />

                

                  <Box className={classes.contents}  display={{xs:"block",sm:"block",md:"none"}}>
                      {Review.content ? parse(Review.content):null}
                  </Box>









                    <LatestProjects/>
                    <PopularProjects/>
                    <HorizontalAds/>
              </Container>);
}







const mapStateToProps = (state, ownProps) => {


  return {
    Review: state.firestore.ordered.Review && state.firestore.ordered.Review[0] ? state.firestore.ordered.Review[0] : null,
    //LinkedProjects: state.firestore.ordered.LinkedProjects,
    //categoriesList: state.firestore.ordered.categoriesList,
    //ProjectsMeta: state.firestore.ordered.ProjectsMeta,
    Loaded:isLoaded(state.firestore.ordered.Review),
    Empty:isEmpty(state.firestore.ordered.Review) 
  }
}

export default connect(mapStateToProps)(View);



