import React, { useEffect } from 'react';
import Container from '@mui/material/Container';

//import { getAnalytics, logEvent } from "firebase/analytics";
import LatestProjects from "../components/Projects/LatestProjects"
import PopularProjects from "../components/Projects/PopularProjects"

import { makeStyles } from "@mui/styles";
import mainStyle from "../assets/mainStyle";
import { Box } from '@mui/material';
const useStyles = makeStyles( {
    ...mainStyle,
    root:{
        paddingTop: "50px;",
    },
    Message:{
        textAlign: "center;",
        marginBottom: "100px;",
    }
});

const View = () => {
    const classes = useStyles();


    //const analytics = getAnalytics();
    //logEvent(analytics,"page_view",{"page_location":window.location.pathname})
  
        document.title = 'SPRIED - Error'
 
    
      
    return (
            <Container className={classes.root}>
                <Box className={classes.Message}>
                    <h1><strong>404.</strong> Could not find page.</h1>
                    <p>We could not find the page you were looking for.</p>
                </Box>
                <LatestProjects/>
                <PopularProjects/>
            </Container>
        )
    
}
export default View
