import React from 'react';
import { makeStyles } from '@mui/styles';

import { connect } from 'react-redux'
import { isLoaded  } from  'react-redux-firebase'

//import { getAnalytics, logEvent } from "firebase/analytics";

import parse from 'html-react-parser';
import Container from '@mui/material/Container';

import Loader from "../components/other/Loader"

import { Helmet } from 'react-helmet';

import HorizontalAds from "../components/ads/HorizontalAds"
import PageNumbers from "../components/Projects/PageNumbers"
import ListProjects from "../components/Projects/ListProjects"

import { useFirestoreConnect } from 'react-redux-firebase'

import mainStyle from "../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    margin: '0 ',
    marginTop: '0',
  },
}));


function Home({Page,Projects,Loaded}) { 
  const classes = useStyles();
  //const analytics = getAnalytics();
  //logEvent(analytics,"page_view",{"page_location":window.location.pathname})
  var title = 'Tinkers Projects - Imagine | Develop | Create'
  
    
  useFirestoreConnect([
    { collection: 'Pages', storeAs: 'Page', where:[["path",'==',"/"],["status",'==',"public"]]},
    { collection: 'Projects', storeAs: 'HomeProjects', orderBy:["date",'desc'],where:[["status","==","public"]]},
])
  
  return (<Container>


    
<Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>



                <link rel="canonical" href={`http://tinkersprojects.com/projects`} />



                
                <meta property="og:title" content={title}/>
                <meta property="og:type" content="artical" />
                <meta property="og:url" content="https://tinkersprojects.com/"/>

                <meta property="og:site_name" content="Tinkers Projects"/>
                <meta name="twitter:image:alt" content={title}/>


                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={title}/>
                <meta name="twitter:url" content="https://tinkersprojects.com/"/>


            </Helmet>


                {!Loaded ? <Loader/> :
                  <main className={classes.root}>
                    <HorizontalAds/>

                                        {Page && Page.content  && Page.path === window.location.pathname ? parse(Page.content) : null}

                    <HorizontalAds/>

                    <ListProjects Projects={Projects}/>
                    
                    <PageNumbers/>
                    <HorizontalAds/>
                  </main>
                }
              </Container>
          );
}







const mapStateToProps = (state, ownProps) => {
  return {
    Projects:  state.firestore.ordered.HomeProjects ,
    Page: state.firestore.ordered.Page && state.firestore.ordered.Page[0] ? state.firestore.ordered.Page[0] : null,
    Loaded:isLoaded(state.firestore.ordered.HomeProjects) && isLoaded(state.firestore.ordered.Page)
  }
}

export default connect(mapStateToProps)(Home);



