import React from 'react';
import { makeStyles } from '@mui/styles';

import { Link  } from 'react-router-dom'
import { connect } from 'react-redux'
import {  useParams} from "react-router-dom";

//import { getAnalytics, logEvent } from "firebase/analytics";
import parse from 'html-react-parser';
import { useFirestoreConnect } from 'react-redux-firebase'
import { isLoaded,isEmpty  } from  'react-redux-firebase'

import { Helmet } from 'react-helmet';

import {Divider ,} from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Loader from "../../components/other/Loader"
import Page404 from "../Page404"


import LatestProjects from "../../components/Projects/LatestProjects"
import PopularProjects from "../../components/Projects/PopularProjects"

import HorizontalAds from "../../components/ads/HorizontalAds"
import Spacer from "../../components/other/Spacer"

import ListProjects from "../../components/Projects/ListProjects"
import ImageGallery from "../../components/Projects/ImageGallery"


import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  ImageGalleryBox:{
      width:"300px",
      minWidth: "300px;",
      display:"block",
      ['@media (max-width:899px)']: { // eslint-disable-line no-useless-computed-key
          width: '100%',
          minWidth: "100%;",
      }
  },
  TitleBlock:{
    marginBottom:"30px"
  },
  Name:{
    margin:0,
    //topMargin:"10px",
    marginBottom:"0px"
  },
  GridLayout:{
    display: "flex;",
    position: "relative;",
    //alignItems: "center;",
  },
  MainText:{
    flexGrow:1
  },
  CodeBlock:{
    overflow: "scroll;",
    height: "90%;",
    maxHeight: "400px;",
    background: "#eee;",
    fontSize: "15px;",
    lineHeight: "1.6;",
    maxWidth: "100%;",
    padding: "1.6em;",
  }
}));


function View({Empty,Project,Loaded,categoriesList,LinkedProjects,ProjectsMeta}) { 
  const classes = useStyles();

  //const analytics = getAnalytics();

  let params = useParams();

  var whereQuery = [["status","==","public"]]
  

  if(params && params.id)
  {
    whereQuery.push(["slug","==",params.id])
  }

  var Query = [
    { collection: 'Projects', storeAs: 'Project', where:whereQuery},
  ]

  //document.title = 'Tinkers Projects - ' +(Project && Project.title ? Project.title  : "Project")
  


  if(Project && Project.id)
  {
    //logEvent(analytics,"page_view",{"page_location":window.location.pathname})
    Query.push({ collection: 'Projects', storeAs: 'ProjectsMeta',doc:Project.id.toString(), subcollections:[{collection: 'Meta',limit:1000 }]})
  }

  if(Project && Project.Links && Array.isArray(Project.Links) && Project.Links.length > 0)
  {
    Query.push({ collection: 'Projects', storeAs: 'LinkedProjects', where:[["status","==","public"],["id","in",Project.Links]]})
  }

  if(Project && Project.categories && Array.isArray(Project.categories) && Project.categories.length > 0)
  {
    Query.push({ collection: 'Categories', storeAs: 'categoriesList', where:[["status","==","public"],["slug","in",Project.categories]]})
  }
  
  useFirestoreConnect(Query)


if(!Loaded)
  return(<Loader/>)

if(Empty || !params || !params.id)
  return(<Page404/>)


  const ImageGalleryImages =  Project.galleryImages

  return (
              <Container>


                
            <Helmet>
                <meta charSet="utf-8" />
                <title>{'Tinkers Projects - ' +(Project && Project.title ? Project.title  : "Project")}</title>



                <link rel="canonical" href={`http://tinkersprojects.com/project/${Project && Project.slug}`} />
                <meta name='description' content={ Project.content} />



                
                <meta property="og:title" content={'Tinkers Projects - ' +(Project && Project.title ? Project.title  : "Project")}/>
                <meta property="og:type" content="artical" />
                <meta property="og:image" content={Project.thumbnail}/>
                <meta property="og:url" content={`http://tinkersprojects.com/project/${Project && Project.slug}`}/>

                <meta property="og:description" content={Project.content}/>
                <meta property="og:site_name" content="Tinkers Projects"/>
                <meta name="twitter:image:alt" content={'Tinkers Projects - ' +(Project && Project.title ? Project.title  : "Project")}/>


                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={'Tinkers Projects - ' +(Project && Project.title ? Project.title  : "Project")}/>
                <meta name="twitter:description" content={Project.content}/>
                <meta name="twitter:image" content={Project.thumbnail}/>
                <meta name="twitter:url" content={`http://tinkersprojects.com/project/${Project && Project.slug}`}/>


            </Helmet>


                    <HorizontalAds/>

                <Box className={classes.MainText} display={{xs:"block",sm:"block",md:"none"}}>
                  <Box className={classes.TitleBlock}>
                    <h2 className={classes.Name}>{Project.title ? Project.title:null}</h2>
                    
                    {categoriesList && categoriesList.length > 0 ? <Box>
                          <strong>Categories:</strong> 
                            { categoriesList.map((item, index) =>{
                              return (<>{index > 0 ? "," : null} <Link  to={"/category/"+item.slug}>{item.name}</Link ></>)
                            })}
                          </Box> : null}
                          
                        {Project && Project.tags && Array.isArray(Project.tags) && Project.tags.length > 0 ? <Box>
                          <strong>Tags:</strong> 
                            { Project.tags.map((item, index) =>{
                              return (<>{index > 0 ? "," : null} <Link  to={"/tag/"+item}>{item}</Link ></>)
                            })}
                          </Box> : null}
                        {/*Project. ? <><Typography variant="h4" component={Link}  to={"/profile/"+IssuesDetails.owner} className={classes.OwnerProfile}>by {Profiles[IssuesDetails.owner].displayName}</Typography><p className={classes.Seperator}>|</p></>:null*/}
                        

                          {Project && (Project.url ||Project.client ||Project.product)   ? <Divider /> : null}
                          {Project && Project.url  ? <Box><a target="_blank" rel="noreferrer" href={Project.url}>{Project.url}</a ></Box> : null}
                          {Project && Project.client  ? <Box><a target="_blank" rel="noreferrer" href={Project.client}>{Project.client}</a ></Box> : null}
                          {Project && Project.product  ? <Box><strong>product:</strong> <a target="_blank" rel="noreferrer" href={Project.product}>{Project.product}</a ></Box> : null}

                        </Box>
                </Box>





                <Box className={classes.GridLayout}>
                    {Project && (Project.galleryImages || Project.image) ?
                      <>
                        <Box className={classes.ImageGalleryBox}>
                            <ImageGallery projectDetails={Project ? Project:null} Attachments={ImageGalleryImages}/>
                        </Box>
                        <Spacer width="20px" display={{xs:"none",sm:"none",md:"block"}}/>
                      </>
                    :null}
                    

                    <Box className={classes.MainText} display={{xs:"none",sm:"none",md:"block"}}>
                  <Box className={classes.TitleBlock}>
                        <h2 className={classes.Name}>{Project.title ? Project.title:null}</h2>
                        
                        {categoriesList && categoriesList.length > 0 ? <Box>
                          <strong>Categories:</strong> 
                            { categoriesList.map((item, index) =>{
                              return (<>{index > 0 ? "," : null} <Link  to={"/category/"+item.slug}>{item.name}</Link ></>)
                            })}
                          </Box> : null}
                          
                        {Project && Project.tags && Array.isArray(Project.tags) && Project.tags.length > 0 ? <Box>
                          <strong>Tags:</strong> 
                            { Project.tags.map((item, index) =>{
                              return (<>{index > 0 ? "," : null} <Link  to={"/tag/"+item}>{item}</Link ></>)
                            })}
                          </Box> : null}



                          {Project && (Project.url ||Project.client ||Project.product)   ? <Divider /> : null}
                          {Project && Project.url  ? <Box><a target="_blank" rel="noreferrer" href={Project.url}>{Project.url}</a ></Box> : null}
                          {Project && Project.client  ? <Box><a target="_blank" rel="noreferrer" href={Project.client}>{Project.client}</a ></Box> : null}
                          {Project && Project.product  ? <Box><strong>product:</strong> <a target="_blank" rel="noreferrer" href={Project.product}>{Project.product}</a ></Box> : null}



                        
                    </Box>
                        <Box className={classes.contents}>
                            {Project.content ? parse(Project.content):null}
                        </Box>
                    </Box>
                </Box>


                <Box>
                  { ProjectsMeta && ProjectsMeta.map((item)=>{
                    switch (item.type) {
                      case "code":
                        return <Box>
                                  <h2>{item.title?item.title:"Code for Project"}</h2>
                                  <a  rel="noreferrer" target="_blank" href="https://www.gnu.org/licenses/gpl-3.0.en.html">GNU General Public License v3.0</a>
                                  <pre className={classes.CodeBlock}>{item.code}</pre>
                                </Box>

                      case "youtube":
                        return null

                      /*case "file":
                        return null
                        break;**/

                      default:
                        break;
                    }

                    return null
                  })}
                </Box>
                <HorizontalAds  display={{xs:"block",sm:"block",md:"none"}} />

                

                  <Box className={classes.contents}  display={{xs:"block",sm:"block",md:"none"}}>
                      {Project.content ? parse(Project.content):null}
                  </Box>








                  {Project && Project.Links && Array.isArray(Project.Links) && Project.Links.length > 0 ?
                    <>
                      <h2>Related Projects</h2>
                      <ListProjects Projects={LinkedProjects}/>
                      <HorizontalAds/>
                    </>
                  :null}


                    <LatestProjects/>
                    <PopularProjects/>
                    <HorizontalAds/>
              </Container>);
}







const mapStateToProps = (state, ownProps) => {


  return {
    Project: state.firestore.ordered.Project && state.firestore.ordered.Project[0] ? state.firestore.ordered.Project[0] : null,
    LinkedProjects: state.firestore.ordered.LinkedProjects,
    categoriesList: state.firestore.ordered.categoriesList,
    ProjectsMeta: state.firestore.ordered.ProjectsMeta,
    Loaded:isLoaded(state.firestore.ordered.Project),
    Empty:isEmpty(state.firestore.ordered.Project) 
  }
}

export default connect(mapStateToProps)(View);



