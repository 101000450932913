import React, {useState  } from 'react'
import {Container,TextField,Button} from '@mui/material';

import { compose } from 'redux'
import { firestoreConnect,isLoaded  } from  'react-redux-firebase'

import Alert from '@mui/material/Alert';

//import { getAnalytics, logEvent } from "firebase/analytics";

import { makeStyles } from '@mui/styles';
//import {SubmitContact} from '../store/actions/ContactAction'
import { connect } from 'react-redux'
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';

import { NavLink } from 'react-router-dom'
import axios from 'axios'
import parse from 'html-react-parser';

import Loader from "../components/other/Loader"
import HorizontalAds from "../components/ads/HorizontalAds"

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress'

import mainStyle from "../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    margin: '0 ',
    marginTop: '0',
  },
}));

var timeoutTimer = null

function Contact({header,Loaded ,Page}) { 
  const classes = useStyles();
    
  const [values, setValues] = useState({});
  const [{Loading,finished,error}, setForm] = useState({Loading:false, finished:false,error:false});

  
  //const analytics = getAnalytics();
  //logEvent(analytics,"page_view",{"page_location":window.location.pathname})
  
  document.title = 'Tinkers Projects - Contact'

  if(finished)
      return (
        <Container>
          <main className={classes.root}>
              <h2>Contact</h2> 
              <h3>Thank you</h3>

              <Button component={NavLink} to="/" variant="contained" color="primary">
                  Go home
              </Button>

          </main>
      </Container>)

  if(Loading)
      return (
        <Container>
          <main className={classes.root}>
              <h2>Contact</h2> 
              <Box className={classes.loading}>
        <CircularProgress  className={classes.CircularProgress}/>
        <h4>Loading</h4>

    </Box>
          </main>
      </Container>)


  const handleChange = (event) => {
      setValues({
          ...values,
          [event.target.name]: event.target.value
      });
  };

  
  const handleVerify=token => {
      setValues({
          ...values,
          token: token
      });
  }

  const handleTimeout = () => {
      console.log()
      if(!finished)
      {
        setForm({Loading:false, finished:false, error:true});
        clearInterval(timeoutTimer);
      }
  };

  const handleSubmit = (event) => { 
      event.preventDefault();
      console.log("submit")
      timeoutTimer = setTimeout(handleTimeout, 30000)
      setForm({Loading:true, finished:false, error:false});
      var MessageData = {};

      MessageData.service = "Tinkers Projects";

      if(values.name)
          MessageData.name = values.name;

      if(values.token)
          MessageData.token = values.token;
  
      if(values.email)
          MessageData.email = values.email;
          
      if(values.message)
          MessageData.message = values.message;

      axios.post('https://api.spried.com/contact',MessageData).then(Result => {
          console.log("Contact Result")
          console.log(Result)
          clearInterval(timeoutTimer);
          setForm({Loading:false, finished:true, error:false});
          
      }).catch(err => {
          clearInterval(timeoutTimer);
          setForm({Loading:false, finished:false, error:true});
      });  
  };






  return (
      <Container>
        
        {!Loaded ? <Loader/> :
          <main className={classes.root}>


                                {Page && Page.content  && Page.path === window.location.pathname ? parse(Page.content) : null}

            <HorizontalAds/>

            <h2>Contact</h2> 
            <h3>submit form to Contact</h3>

            {error ? <Alert severity="error">Error — Try again later.</Alert> : null}


              <form onSubmit={handleSubmit} className={classes.ContactForm} noValidate autoComplete="off">
                <TextField margin="normal" fullWidth onChange={handleChange} name="name" className={classes.TextField} id="standard-basic" variant="outlined" label="Name" />
                <TextField margin="normal" fullWidth onChange={handleChange} name="email" className={classes.TextField} id="standard-basic" variant="outlined" label="Email" />
                <TextField margin="normal" fullWidth onChange={handleChange} name="message" className={classes.TextField} id="standard-basic" variant="outlined" label="Message" multiline rows={4}/>
                {values && !values.token ? <GoogleReCaptcha onVerify={handleVerify} /> : null}

                <Button margin="normal"  type="submit" className={classes.SendButton} variant="contained">Send</Button>
              </form>

            
            <HorizontalAds/>
          </main>
        }
      </Container>);

}









const mapStateToProps = (state, ownProps) => {

  return {
    Page:state.firestore.ordered.Page,
    Loaded :isLoaded(state.firestore.ordered.Page)
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props =>{
    return [
      { collection: 'Pages', storeAs: 'Page', where:[["path",'==','/contact-me'],["status",'==',"public"]]}
    ]
})
)(Contact);