import React from 'react'

import { makeStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import { Link  } from 'react-router-dom'
import Box from '@mui/material/Box';

import AdSense from 'react-adsense';


import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles({
...mainStyle,
    IssueBox:{
      //marginBottom:"5px",
      minHeight:"300px",
      marginBottom:"50px"
    },
    ImageBox:{
        //background:"#f3f3f3",
        height:"230px",
        width:"100%",
        color:"#bbb",
        textAlign: "center",
        fontSize:"40px", 
        display: "flex;",
        alignItems: "center;"
    },
    ImageIcon:{
        marginTop:"100px",
        fontSize: "40px;"
    },
    Image:{
        maxHeight:"230px",
        maxWidth:"100%",
        width:"100%",
        height:"230px",
        textDecoration: "none",   
         objectFit: "contain"
    },
    title:{
        margin:"10px 0 0 0",
        whiteSpace: "nowrap;",
        overflow: "hidden;",
        textOverflow: "ellipsis;",
        width:"100%",
        fontWeight:" 600;",
        color: "#1f1f1f;"
    },
    excerpt:{
        width:"100%",
        wordWrap:" break-word;",
        color: "#1f1f1f;"
    },
    date:{
        margin:"0",
        whiteSpace: "nowrap;",
        overflow: "hidden;",
        textOverflow: "ellipsis;",
        "&>*":{
            color: "#19A78E;",
            fontSize: "20px;",
            margin:"0 5px -4px 0",
        }
    },
    Link :{
        textDecoration: "none;",
    },
    ReadMore:{
        color: "#1f1f1f;",
        fontWeight: "700;",
        margin:0
    }
});

const View = ({Review,xs_max,sm_max,md_max,lg_max,xl_max}) => {
    
    const classes = useStyles();

    if(!Review) return null


    return (<Grid container spacing={3}>
            {Review.map((value,index) => {

                if(!value.title)
                    return null

                var HiddenDisplay = {};
                if(xs_max && xs_max < index+1)
                    HiddenDisplay.xs = "none"
                else
                    HiddenDisplay.xs = "block"

                if(sm_max && sm_max < index+1)
                    HiddenDisplay.sm = "none"
                else
                    HiddenDisplay.sm = "block"

                if(md_max && md_max < index+1)
                    HiddenDisplay.md = "none"
                else
                    HiddenDisplay.md = "block"

                if(lg_max && lg_max < index+1)
                    HiddenDisplay.lg = "none"
                else
                    HiddenDisplay.lg = "block"

                if(xl_max && xl_max < index+1)
                    HiddenDisplay.xl = "none"
                else
                    HiddenDisplay.xl = "block"


                //const Image = value.image ? value.image : null;
                //const Name  = value.title;
                var excerpt  = (value.excerpt ? value.excerpt : value.content  ? value.content : "").replace(/<[^>]+>/g, ' ')
                excerpt  = excerpt.slice(0,  excerpt.lastIndexOf(" ", 200));
               
            const handleClick = () =>{
                window.scrollTo(0, 0);
            }

                      

                return (<>

                    {index%12 === 0 && index !== 0? 
                        <Grid item sx={{ display:HiddenDisplay }}  xs={12} sm={6}  md={4}  lg={3}  xl={3}  spacing={3}>
                            <AdSense.Google
                                client='ca-pub-3720788323192251'
                                slot='5577314090'
                                style={{ display: 'block' }}
                                format='auto'
                                responsive='true'
                                //layoutKey='-gw-1+2a-9x+5c'
                            />
                        </Grid>
                    : null }

                    <Grid item sx={{ display:HiddenDisplay }}  xs={12} sm={6}  md={4}  lg={3}  xl={3}  spacing={3}>
                        <Link onClick={handleClick} to={"/reviews/"+(value.slug?value.slug:value.id)} className={classes.Link }>
                            <Box  className={classes.IssueBox}>
                                <Box  className={classes.ImageBox}>
                                    <img alt={value.title} src={value.thumbnail ? value.thumbnail  :"/images/imageNotFound.png"} className={ classes.Image}/>
                                </Box>
                                <h3 className={classes.title}>{value.title}</h3>
                                <p className={classes.excerpt}>{excerpt}...</p>
                                <p className={classes.ReadMore}>Read More</p>
                            </Box>
                        </Link >
                    </Grid>
                    </>)
            })}
    </Grid>

        
    )
}


export default (View);