import React from 'react'

import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles({
    ...mainStyle,
    SpaceBox: {
    }
});


const Spacer = ({display,width}) => {
    const classes = useStyles();

    var style = {}

    if(width)
    {
        style.width = width
        style.minWidth = width
    }
    else
    {
        style.flexGrow = 1
    }

    return (<Box style={style} display={display}  className={classes.SpaceBox}>
        <div></div>
        </Box>)
}

export default (Spacer);