

// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
//import { getAnalytics, logEvent } from "firebase/compat/analytics";

//import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDOWRCOs8iX9pmDjB-WOc0yKW2an8FNSEw",
  authDomain: "tinkers-projects.firebaseapp.com",
  projectId: "tinkers-projects",
  storageBucket: "tinkers-projects.appspot.com",
  messagingSenderId: "24816360028",
  appId: "1:24816360028:web:4c3d68697df5ee11fea499",
  measurementId: "G-P1KJV96YEE"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

//const analytics = getAnalytics();
//logEvent(analytics, 'notification_received');

export default firebase;
// /export { db };