import React from 'react';

import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect,isLoaded  } from  'react-redux-firebase'


import Box from '@mui/material/Box';

import Loader from "../other/Loader"
import ListProjects from "./ListProjects"
/*
import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ... mainStyle,
  root: {
    margin: '0 ',
    marginTop: '0',
  },
}));*/


function View({Projects,Loaded}) { 
  //const classes = useStyles();
  
  if(!Loaded)
    return(<Loader/>);

  return (<Box>
    <h2>Popular projects</h2>
    <ListProjects Projects={Projects}  xs_max={4} sm_max={4} md_max={3} lg_max={4} xl_max={4}/>
    </Box>);
}







const mapStateToProps = (state, ownProps) => {
  return {
    Projects:  state.firestore.ordered.PopularProjects ,
    Loaded:isLoaded(state.firestore.ordered.PopularProjects) 
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props =>{
    return [
      { collection: 'Projects', storeAs: 'PopularProjects',limit:4, orderBy:["viewsCount",'desc'],where:[["status","==","public"]]},
    ]
})
)(View);



