import React from 'react';
import { makeStyles } from '@mui/styles';

import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect,isLoaded,isEmpty  } from  'react-redux-firebase'

//import { getAnalytics, logEvent } from "firebase/analytics";
import {useParams} from "react-router-dom";

import { useFirestoreConnect } from 'react-redux-firebase'
import parse from 'html-react-parser';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Loader from "../../components/other/Loader"
import Page404 from "../Page404"

import { Helmet } from 'react-helmet';

import HorizontalAds from "../../components/ads/HorizontalAds"
import PageNumbers from "../../components/Projects/PageNumbers"
import ListProjects from "../../components/Projects/ListProjects"

import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    margin: '0 ',
    marginTop: '0',
  },
  Contents:{
    margin:"40px 0"
  }
}));


function Home({Page,Projects,Loaded,Empty}) { 
  const classes = useStyles();
  let params = useParams();
  var title = "Tinkers Projects - Projects"
  
  //const analytics = getAnalytics();
  //logEvent(analytics,"page_view",{"page_location":window.location.pathname})

  var whereQuery = [["status","==","public"]]
  if(params && params.category)
  {
    whereQuery.push(["categories","array-contains",params.category])
    title = 'Tinkers Projects - Category: '+params.category
  }
  else if(params && params.tag)
  {
    whereQuery.push(["tags","array-contains",params.tag])
    title = 'Tinkers Projects - Tag: '+params.tag
  }
  else
  {
    title = 'Tinkers Projects - Projects'
  }

  useFirestoreConnect([
    { collection: 'Projects', storeAs: 'Projects', orderBy:["date",'desc'],where:whereQuery},
  ])

  if(!Loaded)
  {
    return (<Loader/> )
  }

  if(Loaded && Empty)
  {
    //document.title = 'Tinkers Projects - Not Found'
    return (<Page404/>)
  }

  
  return (
              <Container>




                
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>



                <link rel="canonical" href={`http://tinkersprojects.com/projects`} />



                
                <meta property="og:title" content={title}/>
                <meta property="og:type" content="artical" />
                <meta property="og:url" content="https://tinkersprojects.com/"/>

                <meta property="og:site_name" content="Tinkers Projects"/>
                <meta name="twitter:image:alt" content={title}/>


                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={title}/>
                <meta name="twitter:url" content="https://tinkersprojects.com/"/>


            </Helmet>


                
                  <main className={classes.root}>
                    <HorizontalAds/>
                    <Box className={classes.Contents}>
                      {Page && Page.content && Page.path === window.location.pathname ? 
                        parse(Page.content)
                      :
                        <Box>
                          {params && params.category ?
                            <h2>Category: {params.category}</h2>
                          :params && params.tag ?
                            <h2>Tag: {params.tag}</h2>
                          :
                            <h2>Projects</h2>}
                        </Box>
                      }
                    </Box>
                    <ListProjects Projects={Projects}/>
                    
                    <PageNumbers/>
                    <HorizontalAds/>
                  </main>
              </Container>
          );
}







const mapStateToProps = (state, ownProps) => {
  return {
    Projects:  state.firestore.ordered.Projects ,
    Page: state.firestore.ordered.Page && state.firestore.ordered.Page[0] ? state.firestore.ordered.Page[0] : null,
    Loaded:isLoaded(state.firestore.ordered.Projects) && isLoaded(state.firestore.ordered.Page),
    Empty:isEmpty(state.firestore.ordered.Projects)
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props =>{
    
    const pathname =  window.location.pathname
    return [
      { collection: 'Pages', storeAs: 'Page', where:[["path",'==',pathname]]}
    ]
})
)(Home);



