import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

//import { getAnalytics, logEvent } from "firebase/analytics";
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect,isLoaded  } from  'react-redux-firebase'

import parse from 'html-react-parser';


import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Loader from "../components/other/Loader"

import { useFirestoreConnect } from 'react-redux-firebase'
import HorizontalAds from "../components/ads/HorizontalAds"

import mainStyle from "../assets/mainStyle"
import { NavLink } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    margin: '0 ',
    marginTop: '0',
  },
}));


function View({Page,Tags,Loaded}) { 
  const classes = useStyles();

  //const analytics = getAnalytics();
  //logEvent(analytics,"page_view",{"page_location":window.location.pathname})

  useEffect(() => {
    document.title = 'Tinkers Projects - Tags'
  }, [])
  
  useFirestoreConnect([
    { collection: 'Pages', storeAs: 'Page', where:[["path",'==',"/tags"],["status",'==',"public"]]}
])


const handleClick = () =>{
  window.scrollTo(0, 0);
}


  return (
              <Container>
                
                {!Loaded ? <Loader/> :
                  <main className={classes.root}>

                    {Page && Page.content  && Page.path === window.location.pathname ? parse(Page.content) : null}

                    <HorizontalAds/>
                      <Box>
                                {Tags && Tags.map((TagValue,TagIndex) => {

                                return (<Box><NavLink  onClick={handleClick} to={"/tag/"+TagValue.slug}>{TagValue.name}</NavLink></Box>)
                                })}
                      </Box>
                    <HorizontalAds/>
                  </main>
                }
              </Container>);
}







const mapStateToProps = (state, ownProps) => {
  return {
    Page: state.firestore.ordered.Page && state.firestore.ordered.Page[0] ? state.firestore.ordered.Page[0] : null,
    Tags:state.firestore.ordered.Tags,
    Loaded:isLoaded(state.firestore.ordered.Tags) && isLoaded(state.firestore.ordered.Page)
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props =>{
    return [
      { collection: 'Tags', storeAs: 'Tags', orderBy:"name",where:[["status",'==',"public"]]},
    ]
})
)(View);



