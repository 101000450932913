import React from 'react';
import { makeStyles } from '@mui/styles';

import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect,isLoaded,isEmpty  } from  'react-redux-firebase'

//import { getAnalytics, logEvent } from "firebase/analytics";
//import {useParams} from "react-router-dom";

import { useFirestoreConnect } from 'react-redux-firebase'
import parse from 'html-react-parser';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Loader from "../../components/other/Loader"
//import Page404 from "../Page404"

import HorizontalAds from "../../components/ads/HorizontalAds"
import PageNumbers from "../../components/Projects/PageNumbers"
//import ListProjects from "../../components/Projects/ListProjects"
import ListReview from "../../components/Review/ListReview"

import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    margin: '0 ',
    marginTop: '0',
  },
  Contents:{
    margin:"40px 0"
  }
}));


function Home({Page,Review,Loaded,Empty}) { 
  const classes = useStyles();
  //let params = useParams();
  
  //const analytics = getAnalytics();
  //logEvent(analytics,"page_view",{"page_location":window.location.pathname})

  var whereQuery = [["status","==","public"]]
  /*if(params && params.category)
  {
    whereQuery.push(["categories","array-contains",params.category])
    document.title = 'Tinkers Projects - Category: '+params.category
  }
  else if(params && params.tag)
  {
    whereQuery.push(["tags","array-contains",params.tag])
    document.title = 'Tinkers Projects - Tag: '+params.tag
  }
  else
  {*/
    document.title = 'Tinkers Projects - Review'
  //}

  useFirestoreConnect([
    { collection: 'Reviews', storeAs: 'Review', orderBy:["date",'desc'],where:whereQuery},
  ])

  if(!Loaded)
  {
    return (<Loader/> )
  }

  if(Review)
  {
    //logEvent(analytics,"page_view",{"page_location":window.location.pathname})
  }

  if(Loaded && Empty)
  {
    return (<Container>
              <main className={classes.root}>
                <Box className={classes.Contents} sx={{textAlign:"center"}}>
                  <h2> Reviews coming soon.</h2>
                </Box>
                <HorizontalAds/>
              </main>
            </Container>)
  }


  
  return (
              <Container>
                
                  <main className={classes.root}>
                    <HorizontalAds/>
                    <Box className={classes.Contents}>
                      {Page && Page.content && Page.path === window.location.pathname ? 
                        parse(Page.content)
                      :
                        <Box>
                          {/*params && params.category ?
                            <h2>Category: {params.category}</h2>
                          :params && params.tag ?
                            <h2>Tag: {params.tag}</h2>
                          :*/}
                            <h2>Review</h2>
                        </Box>
                      }
                    </Box>
                    <ListReview Review={Review}/>
                    
                    <PageNumbers/>
                    <HorizontalAds/>
                  </main>
              </Container>
          );
}







const mapStateToProps = (state, ownProps) => {
  return {
    Review:  state.firestore.ordered.Review ,
    Page: state.firestore.ordered.Page && state.firestore.ordered.Page[0] ? state.firestore.ordered.Page[0] : null,
    Loaded:isLoaded(state.firestore.ordered.Review) && isLoaded(state.firestore.ordered.Page),
    Empty:isEmpty(state.firestore.ordered.Review)
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props =>{
    
    const pathname =  window.location.pathname
    return [
      { collection: 'Pages', storeAs: 'Page', where:[["path",'==',pathname]]}
    ]
})
)(Home);



