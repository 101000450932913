import React from 'react';
import { makeStyles } from '@mui/styles';
import { useLocation   } from 'react-router-dom'

//import { getAnalytics, logEvent } from "firebase/analytics";
import { connect } from 'react-redux'
import { isLoaded,isEmpty  } from  'react-redux-firebase'

import { useFirestoreConnect } from 'react-redux-firebase'


import { Helmet } from 'react-helmet';

import parse from 'html-react-parser';
import Container from '@mui/material/Container';

import Loader from "../components/other/Loader"
import Page404 from "./Page404"

import HorizontalAds from "../components/ads/HorizontalAds"

import mainStyle from "../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    margin: '0 ',
    marginTop: '0',
  },
}));


function Home({Page,Loaded,Empty,Redirects}) { 
  const classes = useStyles();
  
  const [Update, setUpdate] = React.useState(window.location.pathname);

  //const analytics = getAnalytics();

  if(Update !== window.location.pathname)
    setUpdate(window.location.pathname)
  
  const RedirectPath = window.location.pathname + (window.location.pathname.substr(window.location.pathname.length - 1)!=="/"?"/":"");

  console.log("RedirectPath")
  console.log(RedirectPath)
  
  useFirestoreConnect([
    { collection: 'Pages', storeAs: 'Page', where:[["path",'==',window.location.pathname],["status",'==',"public"]]},
    { collection: 'Redirects', storeAs: 'Redirects',limit:1, where:[["path",'==',RedirectPath]]}
  ])


  useLocation(state => {
    setUpdate(window.location.pathname)
    console.log("Update")
    console.log(Update)
})

  var title = 'Tinkers Projects'+ (Loaded && Page && Page.name ? " - "+Page.name : "")
  
  if(!Loaded)
    return (<Loader/>)
    
  if(Empty && Redirects)
     window.location.href =Redirects.to

  if(Empty)
    return (<Page404/>)
    
  //logEvent(analytics,"page_view",{"page_location":window.location.pathname})
  
  return (
              <Container>


                
<Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>



                <link rel="canonical" href={`http://tinkersprojects.com`+RedirectPath} />



                
                <meta property="og:title" content={title}/>
                <meta property="og:type" content="artical" />
                <meta property="og:url" content={`http://tinkersprojects.com`+RedirectPath}/>

                <meta property="og:site_name" content="Tinkers Projects"/>
                <meta name="twitter:image:alt" content={title}/>


                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={title}/>
                <meta name="twitter:url" content={`http://tinkersprojects.com`+RedirectPath}/>


            </Helmet>


                
                  <main className={classes.root}>

<HorizontalAds/>
                    {Page && Page.title ? Page.title : null}
                    {Page && Page.content && Page.path === window.location.pathname ? parse(Page.content) : null}

                    <HorizontalAds/>
                  </main>
              </Container>);
}







const mapStateToProps = (state, ownProps) => {


  const RedirectPath = window.location.pathname + (window.location.pathname.substr(window.location.pathname.length - 1)!=="/"?"/":"");
  return {
    Page: state.firestore.ordered.Page && state.firestore.ordered.Page[0] && state.firestore.ordered.Page[0].path === window.location.pathname? state.firestore.ordered.Page[0] : null,
    Redirects: state.firestore.ordered.Redirects && state.firestore.ordered.Redirects[0] && state.firestore.ordered.Redirects[0].path === RedirectPath? state.firestore.ordered.Redirects[0] : null,
    Loaded: isLoaded(state.firestore.ordered.Page),
    Empty: isEmpty(state.firestore.ordered.Page)
  }
}

export default 
  connect(mapStateToProps)
(Home);



