import React from 'react'
import { NavLink } from 'react-router-dom'

import { makeStyles } from "@mui/styles";
import Container from '@mui/material/Container';

import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles({
    ...mainStyle,
    footer:{
        borderTop: "1px solid #eee;",
        fontSize: "16px;",
        background:"#fff",
        paddingBottom: "1em;",
    
    },
    copyright:{
        fontSize: "14px;",
        color: "#333",
        fontFamily: '"Libre Franklin", "Helvetica Neue", helvetica, arial, sans-serif;',
        fontWeight: "400;",
        lineHeight: "1.66;",
        textDecoration: "none",
        "& a":{
            textDecoration: "none",
            fontWeight: "700;",
            color: "#333",
        },
        "& a:hover":{
            textDecoration: "underline"
        }
    },
});


const Footer = () => {

    //const {user} = props

    const classes = useStyles();
    const Now = new Date();

    
    const handleClick = () =>{
        window.scrollTo(0, 0);
    }


    return (
        <footer className={classes.footer}>
            <Container className={classes.copyright}>
                Copyright © { Now.getFullYear()} – <a href="https://tinkersprojects.com/">Tinkerers Projects</a> – <NavLink onClick={handleClick} to="/privacy-policy">Privacy Policy</NavLink> 
            </Container>
        </footer>
    )
}

export default (Footer)