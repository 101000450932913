import React from 'react';
import { makeStyles } from '@mui/styles';
import { useLocation   } from 'react-router-dom'

import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect,isLoaded,isEmpty  } from  'react-redux-firebase'

import {  useParams} from "react-router-dom";
import { useFirestoreConnect } from 'react-redux-firebase'

//import { getAnalytics, logEvent } from "firebase/analytics";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import parse from 'html-react-parser';

import Loader from "../components/other/Loader"
import Page404 from "./Page404"

import HorizontalAds from "../components/ads/HorizontalAds"

import mainStyle from "../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    margin: '0 ',
    marginTop: '0',
  },
  image:{
    maxWidth:"100%"
  },
  imageBox:{
    maxWidth:"100%",
    textAlign: "center;"
  }
}));


function Home({Page,Loaded,Empty,Attachment}) { 
  const classes = useStyles();
  
  const [Update, setUpdate] = React.useState(window.location.pathname);

  
  //const analytics = getAnalytics();
  //logEvent(analytics,"page_view",{"page_location":window.location.pathname})

  if(Update !== window.location.pathname)
    setUpdate(window.location.pathname)
/*
  console.log("Update")
  console.log(window.location.pathname)*/
  
  
  let params = useParams();

  useFirestoreConnect([
    { collection: 'Pages', storeAs: 'Page', where:[["path",'==',Update],["status",'==',"public"]]},
    { collection: 'Attachments', storeAs: 'Attachment', where:[["name",'==',params && params.id ? params.id : ""],["status",'==',"public"]]}
  ])
  console.log(Attachment)
  console.log(params)

  useLocation(state => {
    setUpdate(window.location.pathname)
    /*console.log("Update")
    console.log(Update)*/
})

  document.title = 'Tinkers Projects'+ (Loaded && Attachment && Attachment.name ? "-"+Attachment.name : Page && Page.title ? " - "+Page.title : "")
  
if(!Loaded)
  return (<Loader/>)

if(!Attachment || !Empty || !["png","jpg","jpeg"].includes(params.id.split(".")[params.id.split(".").length-1]))
  return (<Page404/>)
  
  return (
              <Container>
                
                  <main className={classes.root}>
                  <HorizontalAds/>

                    <Box>
                      {Page && Page.title ? <h2>{Page.title}</h2> : Attachment && Attachment.name?<h2>{Attachment.name}</h2>:null}
                      {Page && Page.content  && Page.path === window.location.pathname ? parse(Page.content) : null}
                    </Box>

                    <Box className={classes.imageBox}>
                      <img src={ Attachment.url} alt={Attachment.name} className={classes.image} />
                    </Box>

                    <HorizontalAds/>
                  </main>
              </Container>);
}







const mapStateToProps = (state, ownProps) => {


  return {
    Page: state.firestore.ordered.Page && state.firestore.ordered.Page[0] && state.firestore.ordered.Page[0].path === window.location.pathname? state.firestore.ordered.Page[0] : null,
    Attachment:state.firestore.ordered.Attachment && state.firestore.ordered.Attachment[0] ? state.firestore.ordered.Attachment[0] : null,
    Loaded: isLoaded(),
    Empty: isEmpty(state.firestore.ordered.Page) ||  isEmpty(state.firestore.ordered.Attachment)
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props =>{
    const pathname =  window.location.pathname
    return [
      { collection: 'Pages', storeAs: 'Page', where:[["path",'==',pathname]]}
    ]
})
)(Home);



