import React from "react";
import { BrowserRouter, Route, Routes} from 'react-router-dom'


//import { getAnalytics, logEvent } from "firebase/analytics";
import Home from './pages';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Page from './pages/Page';
import Contact from './pages/Contact';
import TagList from './pages/Tags';
import Attachments from './pages/Attachments';

import ProjectDetails from './pages/Projects/Details';
import ProjectsList from './pages/Projects/List';

import ReviewsDetails from './pages/Reviews/Details';
import ReviewsList from './pages/Reviews/List';

//import Maintenance from './views/Maintenance';

import Header from "./components/header/Header"
import Footer from "./components/footer/Footer"

import Links from './pages/Links';
/*
import { makeStyles } from '@mui/styles';
import mainStyle from "./assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    margin: '0 ',
    marginTop: '0',
  },
  white:{
    background:"#fff"
  }
}));
*/
function App() {

  //const classes = useStyles();

    window.addEventListener('locationchange', function(){
      window.scrollTo(0, 0);
      
      //const analytics = getAnalytics();
      //logEvent(analytics,"page_view",{"page_location":window.location.pathname})
  })
    /*const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }*/

  return (
    <>
      <BrowserRouter>
        <Header/>
          <Routes >
            <Route index element={<Home/>} />
            <Route exact path='/privacy-policy' element={<Privacy/>}/>
            <Route exact path='/terms' element={<Terms/>}/>
            <Route exact path='/contact-me' element={<Contact/>}/>
            
            <Route exact path='/projects' element={<ProjectsList/>}/>
            <Route exact path='/projects/page' element={<ProjectsList/>}/>
            <Route exact path='/projects/page/:page' element={<ProjectsList/>}/>
            <Route exact path='/projects/:id' element={<ProjectDetails/>}/>
            
            <Route exact path='/category/' element={<ProjectsList/>}/>
            <Route exact path='/category/:category' element={<ProjectsList/>}/>

            <Route exact path='/links' element={<Links/>}/>

            <Route exact path='/attachment' element={<ProjectsList/>}/>
            <Route exact path='/attachment/:id' element={<Attachments/>}/>

            <Route exact path='/tag' element={<TagList/>}/>
            <Route exact path='/tag/:tag' element={<ProjectsList/>}/>

            <Route exact path='/reviews' element={<ReviewsList/>}/>
            <Route exact path='/reviews/page' element={<ReviewsList/>}/>
            <Route exact path='/reviews/page/:page' element={<ReviewsList/>}/>
            <Route exact path='/reviews/:id' element={<ReviewsDetails/>}/>
            
            <Route path="*" element={<Page/>}/>
          </Routes >
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
